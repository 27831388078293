import environment from './BASE';
import iconSM from 'assets/images/favicon.ico';
import logoSM from 'assets/images/nlsb-logo.png';
const env = environment();

export default {
  ...env,
  api: {
    ...env.api,
    // Overwrite CB API version here
  },
  build_env: 'SM',
  logo: logoSM,
  title: 'Nailie サロンマネージャー',
  favicon: iconSM,
  redirect_sm_location: 'https://salon.nailie.jp/',
  redirect_fss_location: 'https://salon.becolorful.jp/',
};
